import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'

import '@/styles/scalehack/styleCo.scss'

import { cssServiceButton } from '@/components/scalehack/scalehackForm'

export const Complete = (): JSX.Element => {
  return (
    <>
      <main css={cssMain}>
        <section css={cssSection}>
          <h1 css={cssHeading}>送信が完了しました</h1>
          <p css={cssText}>
            お問い合わせいただき、誠にありがとうございます。
            <br />
            3営業日以内を目処にご記載の連絡先へご連絡いたします。
          </p>
          <a
            css={[cssServiceButton, buttonColor]}
            href="https://scalehack.co.jp/"
          >
            トップページへ
          </a>
        </section>
      </main>
    </>
  )
}

const cssMain = css`
  margin-bottom: -2rem;
  background-color: ${color.grayfF8};

  ${mq.pc} {
    margin-bottom: -4rem;
  }
`

// Section
const cssSection = css`
  width: 100%;
  max-width: 632px;
  padding: 60px 16px 205px;
  margin: 0 auto;
  color: ${color.brack111};

  ${mq.pc} {
    padding: 105px 16px 337px;
  }
`

// Heading
const cssHeading = css`
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.8px;

  ${mq.pc} {
    padding-bottom: 32px;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 2.8px;
  }
`

// Text
const cssText = css`
  padding-bottom: 48px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.28px;

  ${mq.pc} {
    padding-bottom: 56px;
    font-size: 18px;
    letter-spacing: 1.8px;
  }
`
const buttonColor = css`
  color: ${color.white};
  background-color: ${color.red1};
`
