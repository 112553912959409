import React from 'react'
import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import { HeaderButtonContainer } from '../headerButtonContainer'
import { mq } from '@/components/media/media'
import { Link } from 'gatsby'
import color from '@/components/lp/scalehack/color'

export const Header = () => {
  return (
    <header css={header}>
      <div css={headerInner}>
        <Link to="/group/scalehack/for-marketing">
          <StaticImage
            css={headerLogo}
            src="../../../../../images/scalehack/for-marketing/logo.png"
            alt="Scalehack for Marketing"
          />
        </Link>
        <HeaderButtonContainer isCompany={true} />
      </div>
    </header>
  )
}

const header = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  background-color: ${color.white};

  ${mq.midSHM} {
    position: static;
    height: 56px;
  }
`
const headerInner = css`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  max-width: 1152px;
`

const headerLogo = css`
  width: 160px;

  ${mq.midSHM} {
    width: 107px;
  }
`
